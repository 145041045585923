import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import AOS from 'aos';
import { TranslateService } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
defineLocale('de', deLocale);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    private localeService = inject(BsLocaleService);

    constructor(private translate: TranslateService) {
        translate.setDefaultLang('de');
    }

    globalContent: any;
    //private readonly globalContentService = inject(GlobalContentService);

    ngOnInit() {
        this.localeService.use('de');
        AOS.init();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            AOS.refresh()
        }, 500)
    }
}
